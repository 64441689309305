import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ArrowLeftIcon } from "@heroicons/react/outline"

import { ReceivedBoxesTable, COLUMNS_ACCESORS } from "./ReceivedBoxesTable"
import { getReplenishmentProductsByLot } from "../../services"
import { UserContext } from "../../hooks/UserContext"

export const Breakdown = ({replenishment, onClickReturnPreviousPage, boxLabelPatterns, show=true}) => {
  const { i18n } = useTranslation()
  const { user } = useContext(UserContext)

  const [hiddenColumns, setHiddenColumns] = useState(Object.values(COLUMNS_ACCESORS))

  useEffect(() => {
    const isAdmin = user.roles?.some(role => ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_WH_ADMIN"].includes(role))
    let newHiddenColumns = [COLUMNS_ACCESORS.SCANNED_BOX, COLUMNS_ACCESORS.RECEIVED_BOXES, COLUMNS_ACCESORS.RECEIVED_PIECES]

    if(!isAdmin || !["PROCESSING"].includes(replenishment?.status)){
      newHiddenColumns = newHiddenColumns.concat(COLUMNS_ACCESORS.IN_PUTAWAY, COLUMNS_ACCESORS.ACTIONS)
    } else{
      newHiddenColumns = newHiddenColumns.concat(COLUMNS_ACCESORS.IN_PUTAWAY_COUNT)
    }

    setHiddenColumns(newHiddenColumns)
  }, [user, replenishment])

  return (
    show ? (
      <section className="flex-1 bg-white">
        <div className={"p-5 text-xl"}>
          <button onClick={onClickReturnPreviousPage} className="flex gap-1 items-center">
            <ArrowLeftIcon className="h-5 w-5"/>
            {i18n.t('replenishments.show_replenishment_boxes.breakdown.title')}
          </button>
        </div>

        <ReceivedBoxesTable
          replenishment={replenishment}
          showFilters={true}
          hiddenColumns={hiddenColumns}
          queryId={"Breakdown"}
          queryFunction={getReplenishmentProductsByLot}
          queryDataKey={"replenishment_products_by_lot"}
          boxLabelPatterns={boxLabelPatterns}
        />
      </section>
    ) : <></>
  )
}
