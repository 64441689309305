import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RefreshIcon } from "@heroicons/react/outline"

import { SkeletonLoader } from "../../components/SkeletonLoader"
import { FetchingIndicator } from "../../components/BasicTable/modules/FetchingIndicator"

export const PutawayProgressBar = ({totalCheckIn, totalPutaway, isLoadingInitialData=false, isLoading=false, onShowBreakDown ,className, show=true, onRefetch}) => {
  const { i18n } = useTranslation()
  const [completedPercentage, setCompletedPercentage] = useState(null)
  const [remainigPutaway, setRemainingPutaway] = useState(null)

  useEffect(() => {
    if(Number.isInteger(totalCheckIn) && Number.isInteger(totalPutaway)){
      const newRemaingPutaway = totalCheckIn - totalPutaway
      const newCompletedPercentage = totalPutaway === 0 && totalCheckIn === 0 ? 0 : parseFloat(((totalPutaway * 100) / totalCheckIn).toFixed(2))

      setCompletedPercentage(newCompletedPercentage)
      setRemainingPutaway(newRemaingPutaway)
    }
  }, [totalCheckIn, totalPutaway])

  return (
    show ? (
      <section className={`flex-1 bg-white p-5 ${className}`}>
        <SkeletonLoader isLoading={isLoadingInitialData}>
          <h3 className="text-lg font-bold mb-4 flex justify-between">
            <div className="flex gap-1 items-center">
              {i18n.t("replenishments.show_replenishment_boxes.putaway_progress_bar.title")}
              <RefreshIcon className="h-5 w-5 cursor-pointer hover:text-gray-400" onClick={onRefetch}/>
            </div>
            <div className="w-1 h-1"><FetchingIndicator isFetching={isLoading}/></div>
          </h3>

          <div className="flex items-center gap-2">
            <progress className="w-full" value={completedPercentage} max={"100"} />
            <span className="text-green-600 font-bold">{`${completedPercentage}%`}</span>
          </div>

          <div className="flex justify-between">
            <div className="flex gap-1">
              <span className="font-bold">
                {i18n.t("replenishments.show_replenishment_boxes.putaway_progress_bar.remaining_pieces",{quantity: remainigPutaway})}
              </span>
              |
              <button className="text-blue-400 hover:text-blue-300" onClick={onShowBreakDown}>
                {i18n.t("replenishments.show_replenishment_boxes.putaway_progress_bar.show_breakdown")}
              </button>
            </div>

            <div className="font-bold text-green-600">
              {i18n.t("replenishments.show_replenishment_boxes.putaway_progress_bar.general_quantity",{putaway_pieces: totalPutaway, check_in_pieces: totalCheckIn})}
            </div>
          </div>
        </SkeletonLoader>
      </section>
    ) : <></>
  )
}
