import axiosInstance from "../helpers/axiosInstance"

export const getBoxReplenishment = async replenishmentId => {
  const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}`)
  return res.data
}

export const getBoxLabelRegex = async replenishmentId => {
  const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/box-label-regex`)
  return res.data
}

export const receiveBoxReplenishment = async (replenishmentId, params) => {
  const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/receive`, params)
  return res.data
}

export const getReplenishmentProductsByLot = async (replenishmentId, params) => {
  const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/replenishment-products-by-lot`, {params: params})
  return res.data
}

export const getInventoryHistory = async (replenishmentId, params) => {
  const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/inventory-history`, {params: params})
  return res.data
}

export const setBoxReplenishmentArrived = async (replenishmentId, params) => {
  const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/arrived`, params)
  return res.data
}

export const setBoxReplenishmentQualityCheck = async (replenishmentId, params) => {
  const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/quality-check`, params)
  return res.data
}

export const startBoxReplenishmentReception = async (replenishmentId, params) => {
  const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/start-reception`, params)
  return res.data
}

export const endBoxReplenishmentReception = async (replenishmentId) => {
  const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/end-reception`)
  return res.data
}

export const reopenBoxReplenishmentReception = async (replenishmentId) => {
  const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/reopen-reception`)
  return res.data
}

export const adjustReceivedQuantity = async (replenishmentId, params) => {
  const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/adjust-received-quantity`, params)
  return res.data
}

export const getBoxReplenishmentProgress = async (replenishmentId) => {
  const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/progress`)
  return res.data
}

export const transferToDamage = async (replenishmentId, params) => {
  const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/box-replenishments/${replenishmentId}/transfer-to-damaged`, params)
  return res.data
}
