import {useEffect, useState} from "react";

export const InputQuantity = ({initialValue = 0, className="", updateData, onlyMinus = false, minIs0 = true, maxValue = Number.MAX_VALUE , enableInput=false, ...props}) => {
    const [value, setValue] = useState(initialValue)

    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {

    }

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const onPlusHandler = () => {
        if ((value+1) <= maxValue) {
            setValue(value+1)
            if(updateData){
                updateData(value+1)
            }
        }
    }

    const onLessHandler = () => {
        console.log(value)
        if ((value-1) >= 0 || !minIs0) {
            setValue(value-1)
            if(updateData){
                updateData(value-1)
            }
        }
        
    }

    const handleOnInput = (e) => {
        let newQuantity = e.target.value

        if(newQuantity === ""){
            setValue(null)
            updateData(null)
            return
        }

        const isValid = /^\d+$/.test(newQuantity)

        if(!isValid){
            return
        }

        newQuantity = parseInt(newQuantity)

        if(newQuantity > maxValue){
            return
        }

        setValue(newQuantity)

        if(updateData){
            updateData(newQuantity)
        }
    }

    return (
        <>
            <div className={`flex flex-row border h-10 ${enableInput ? "w-min" : "w-24"} rounded border-gray-400 relative`}>
                <button
                    className="font-semibold border-r bg-white hover:bg-gray-200 text-gray-900 border-gray-400 h-full w-20 flex rounded-l focus:outline-none cursor-pointer"
                    onClick={onLessHandler}
                >
                    <span className="m-auto">-</span>
                </button>

                <div className={`bg-white ${enableInput ? "w-full" : "w-24"} text-xs md:text-base flex items-center justify-center cursor-default`}>
                    <span>
                        {enableInput ? (
                            <input
                                className="border-0 w-full text-center outline-none"
                                value={[null, undefined, ""].includes(value) ? "" : value}
                                onChange={(e) => handleOnInput(e)}
                            />
                        ) : (
                            <span>{value}</span>
                        )}
                    </span>
                </div>
                {onlyMinus ? 
                
                ""
                
                : 
                (
                    <>
                    <button
                    className="font-semibold border-l  bg-white hover:bg-gray-200 text-gray-800 border-gray-400 h-full w-20 flex rounded-r focus:outline-none cursor-pointer"
                    onClick={onPlusHandler}
                >
                    <span className="m-auto">+</span>
                    </button>
                    </>
                )
                }
                
                {/*<div className="absolute flex flex-col p-2 w-32 md:w-full mt-6 md:mt-8 mt-10 flex items-start justify-center">
                    <svg width="10" height="10" className="fill-current ml-5 md:mx-auto">
                        <polygon points="0 10, 10 10, 5 0"/>
                    </svg>
                    <span
                        className="text-xs block w-48 flex flex-wrap justify-center bg-black p-3 h-auto rounded-lg text-white"
                    >Input validation message</span>
                </div>*/}
            </div>
        </>
    )
}
