export const buildLotUniqueCode = (materialCode, lotCode) => {
  return `${materialCode}-${lotCode}`
}

export const extractMaterialAndUniqueCode = (lotUniqueCode) => {
  const words = lotUniqueCode.split("-")

  if(words.length !== 2){
    return { materialCode: null, lotCode: null }
  }

  return { materialCode: words[0], lotCode: words[1] }
}

export const getBarcodeRegexMatch = (boxLabelPatterns, barcode) => {
  let regexMatch = null

  boxLabelPatterns.forEach(boxLabelPattern => {
    if(regexMatch){
      return
    }
    const regex = new RegExp(boxLabelPattern)
    regexMatch = barcode.match(regex)
  })

  return regexMatch
}

export const getQueryErrorData = (error) => {
  const isErrorDataAnObject = error.response.data.error && typeof error.response.data.error === "object" ? true : false

  const errorData = {
    status: error.response.status,
    statusText: error.response.statusText,
    message: isErrorDataAnObject ? error.response.data.error.message : error.response.data.error,
    code: isErrorDataAnObject ? error.response.data.error.code : null,
    context: isErrorDataAnObject ? error.response.data.error.context : null
  }
  return errorData
}
