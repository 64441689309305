import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import moment from "moment"
import { Tooltip } from "antd"
import { RefreshIcon } from "@heroicons/react/outline"

import { NewTable, Button, DialogView, InputQuantity } from "../../components"
import { getBarcodeRegexMatch, getQueryErrorData } from "./utils"
import { adjustReceivedQuantity, transferToDamage } from "../../services"
import { FetchingIndicator } from "../../components/BasicTable/modules/FetchingIndicator"

export const COLUMNS_ACCESORS = {
  PRODUCT_NAME: "product_name",
  SCANNED_BOX: "scanned_box",
  LOT: "lot",
  SKU: "sku",
  EXPECTED: "expected",
  RECEIVED: "received",
  RECEIVED_BOXES: "received_boxes",
  RECEIVED_PIECES: "received_pieces",
  PIECES_STATUS: "pieces_status",
  IN_PUTAWAY_COUNT: "in_putaway_count",
  IN_PUTAWAY: "in_putaway",
  ACTIONS: "actions"
}

const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 5 // 5 minutes
const DEFAULT_QUERY_CACHE_TIME = 1000 * 60 * 5 // 5 minutes

const FIX_QUANTITY_MODES = {
  DAMAGED: "DAMAGED",
  PUTAWAY: "PUTAWAY"
}

export const ReceivedBoxesTable = ({
  replenishment,
  boxLabelPatterns,
  queryFunction=(replenishmentId, searchParams) => {},
  queryDataKey,
  queryId="received-boxes-table",
  showFilters=true,
  hiddenColumns=[],
  additionalSearchParams={},
  receivedBox={uniqueCode: "", quantity: null}
}) => {
  const initialSearchParams = {id: replenishment.id, page:1, per_page: 10, ...additionalSearchParams}

  const { i18n } = useTranslation()

  const [damagedPiecesDialog, setDamagedPiecesDialog] = useState({show: false, rowData: null, quantity: 0, loading: false, errorMsg: null})
  const [fixQuantityDialog, setFixQuantityDialog] = useState({show: false, rowData: null, quantity: 0, loading: false, errorMsg: null})
  const [searchParams, setSearchParams] = useState({...initialSearchParams})
  const [rowsData, setRowsData] = useState({data: [], meta: null})
  const [rows, setRows] = useState([])
  const [isFetchEnabled, setIsFetchEnabled] = useState(true)

  const [filters, setFilters] = useState([
    {
      key: "box_barcode",
      label: "replenishments.show_replenishment_boxes.received_boxes_table.filters.box_barcode.title",
      data: { visible: true, type: 'text', value: '' }
    },
    {
      key: "sku",
      label: "replenishments.show_replenishment_boxes.received_boxes_table.filters.sku.title",
      data: { visible: false, type: 'text', value: '' }
    },
    {
      key: "upc",
      label: "replenishments.show_replenishment_boxes.received_boxes_table.filters.upc.title",
      data: { visible: false, type: 'text', value: '' }
    },
    {
      key: "lot_unique_code",
      label: "replenishments.show_replenishment_boxes.received_boxes_table.filters.lot_unique_code.title",
      data: { visible: false, type: 'text', value: '' }
    }
  ])

  const {
    isLoading,
    isFetching,
    refetch,
    data
  } = useQuery([queryId, replenishment.id, searchParams], () => {
    const parsedSearchParams = {...searchParams}

    if(parsedSearchParams.box_barcode?.length > 0){
      const regexMatch = getBarcodeRegexMatch(boxLabelPatterns, parsedSearchParams.box_barcode)
      const { lot_unique_code = "null-null", product_code = "null" } = regexMatch?.groups || {}
      parsedSearchParams.expected_lot_code = lot_unique_code
      parsedSearchParams.sku = product_code

      delete parsedSearchParams.box_barcode
    }

    return queryFunction(replenishment.id, parsedSearchParams)
  }, {
      staleTime: DEFAULT_QUERY_STALE_TIME,
      enabled: showFilters ? isFetchEnabled : true,
      cacheTime: showFilters ? 0 : DEFAULT_QUERY_CACHE_TIME,
      onSuccess: (newData) => {
        if(rowsData.data.length === 0){
          setRowsData({data: newData[queryDataKey], meta: newData.meta})
          setIsFetchEnabled(false)
        }
      }
  })

  const columns = [
    {
      accessor: COLUMNS_ACCESORS.PRODUCT_NAME,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.product_name'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.SCANNED_BOX,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.scanned_box'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.LOT,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.lot'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.SKU,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.sku'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.EXPECTED,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.expected'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.RECEIVED,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.received'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.RECEIVED_BOXES,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.received_boxes'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.RECEIVED_PIECES,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.received_pieces'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.PIECES_STATUS,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.pieces_status'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.IN_PUTAWAY_COUNT,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.in_putaway'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.IN_PUTAWAY,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.in_putaway'),
      disableSortBy: true
    },
    {
      accessor: COLUMNS_ACCESORS.ACTIONS,
      Header: i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.columns.actions'),
      disableSortBy: true,
      fetchingIndicator: true
    }
  ]

  const handleOnConfirmDamagedPiecesQuantity = async (lotUniqueCode, sku, quantity) => {
    setDamagedPiecesDialog({...damagedPiecesDialog, loading: true})

    let success = true
    let errorData = null

    const params = { lot_unique_code: lotUniqueCode, quantity: quantity, damaged: true }

    try{
      await transferToDamage(replenishment.id, params)
      //await new Promise((resolve) => setTimeout(resolve, 5000))
    }catch(error){
      errorData = getQueryErrorData(error)
      success = false
    }

    if(!success){
      const errorMsg = i18n.t("replenishments.show_replenishment_boxes.errors.receive_damaged_error", {error_data: JSON.stringify(errorData)})
      setDamagedPiecesDialog({...damagedPiecesDialog, loading: false, errorMsg: errorMsg})
    }else{
      setDamagedPiecesDialog({...damagedPiecesDialog, show: false})
      refetch()
    }
  }

  const handleOnConfirmFixPiecesQuantity = async (lotUniqueCode, quantity, mode) => {
    setFixQuantityDialog({...fixQuantityDialog, loading: true})

    let success = true
    let errorData = null

    const params = { lot_unique_code: lotUniqueCode, correct_quantity: quantity, stock_to_adjust: null }

    try{
      if(mode === FIX_QUANTITY_MODES.PUTAWAY){
        params.stock_to_adjust = "RECEPTION"
        await adjustReceivedQuantity(replenishment.id, params)
      } else if (mode === FIX_QUANTITY_MODES.DAMAGED){
        params.stock_to_adjust = "DAMAGED"
        await adjustReceivedQuantity(replenishment.id, params)
      } else{
        success = false
        errorData = {message: `Unkown mode '${mode}' for adjusting product quantity`}
      }
      //await new Promise((resolve) => setTimeout(resolve, 5000))
    }catch(error){
      errorData = getQueryErrorData(error)
      success = false
    }

    if(!success){
      const errorMsg = i18n.t("replenishments.show_replenishment_boxes.errors.adjust_putaway_pieces", {error_data: JSON.stringify(errorData)})
      setFixQuantityDialog({...fixQuantityDialog, loading: false, errorMsg: errorMsg})
    }else{
      setFixQuantityDialog({...fixQuantityDialog, show: false})
      refetch()
    }
  }

  const updateFiltersData = (updatedData, filter) => {
    // Update filters with new data
    setFilters((prev) => {
      return prev.map((item) => {
        if (item.key === filter)
          return {
            ...item,
            data: filter !== "box_barcode" ? updatedData : {...updatedData, value: updatedData.value}
          }
        else return item
      }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
    })

    // Set search params only if needed
    const filterItem = filters.find(item => item.key === filter)
    if ((Array.isArray(filterItem.data.value) && filterItem.data.value.length === 0 && updatedData.value.length === 0)) return
    if (!['boolean','text'].includes(filterItem.data.type) && filterItem.data.value === updatedData.value) return
    let value = filter !== "box_barcode" ? updatedData.value : updatedData.value
    const filter_name = filterItem.key_alias || filterItem.key

    if (!updatedData.visible) {
      const newSearchParams = {...searchParams, page: 1}
      delete newSearchParams[[filter_name]]
      setSearchParams(newSearchParams)
    }
    else {
      setSearchParams({...searchParams, [filter_name]: value, page: 1})
    }
  }

  useEffect(() => {
    if(receivedBox?.uniqueCode?.length > 0){
      refetch() // Currently refetching, could be modified to update locally
    }
  }, [receivedBox, refetch])

  useEffect(() => {
    if(data){
      setRowsData({data: data[queryDataKey], meta: data.meta})
      setIsFetchEnabled(false)
    }
  }, [data, queryDataKey])

  useEffect(() => {
    if(!showFilters){
      refetch()
    }
  }, [showFilters, refetch])

  useEffect(() =>  {
    const newRows = rowsData.data.map((rowData, rowIndex) => {
      const formattedExpirationDate = rowData.lot ? moment(rowData.lot.expiration_date, "YYYY-MM-DD").format('DD/MM/Y') : null
      const stockCount = { damaged: 0, notDamaged: 0, putaway: 0, total: 0 }
      const boxesCount = { completeReceived: 0, uncompleteReceived: 0, completeExpected: 0, uncompleteExpected: 0, totalReceived: 0, totalExpected: 0 }

      Object.entries(rowData.stock_count).forEach(([stockStatus, count]) => {
        if(stockStatus === "DAMAGED"){
          stockCount.damaged += count
        } else if(["RECEPTION", "RECEPTION-INCOMING-SELL-AHEAD", "RESERVED-RECEPTION-INCOMING-SELL-AHEAD"].includes(stockStatus)){
          stockCount.putaway += count
          stockCount.notDamaged += count
        } else{
          stockCount.notDamaged += count
        }
        stockCount.total += count
      })

      boxesCount.completeReceived = parseInt(stockCount.total / rowData.expected_box_quantity)
      boxesCount.uncompleteReceived = stockCount.total % rowData.expected_box_quantity === 0 ? 0 : 1
      boxesCount.totalReceived = boxesCount.completeReceived + boxesCount.uncompleteReceived

      boxesCount.completeExpected = (rowData.expected_product_quantity / rowData.expected_box_quantity)
      boxesCount.uncompleteExpected = rowData.expected_product_quantity % rowData.expected_box_quantity === 0 ? 0 : 1
      boxesCount.totalExpected = boxesCount.completeExpected + boxesCount.uncompleteExpected

      const scannedBarcode = null // Not yet implemented
      const objectId = parseInt(`${rowData.lot?.id}${rowData.product.id}`)

      return {
        object: { ...rowData, id: objectId },
        product_name: rowData.product.name,
        scanned_box: (
          <div>
            {scannedBarcode && <div className="px-2.5 py-0.5 rounded-full bg-blue-100 text-blue-800 w-max">{scannedBarcode}</div>}

            <div className="flex gap-1">
              <div>{rowData.lot?.unique_code}</div>|<div>{formattedExpirationDate}</div>|<div>{rowData.box_sku}</div>
            </div>
          </div>
        ),
        lot: (
          <div className="flex flex-col gap-2">
            <div>{rowData.lot?.unique_code}</div>
            <div>{formattedExpirationDate}</div>
          </div>
        ),
        sku: (
          <div className="flex flex-col gap-2">
            <div className="flex gap-1 text-xs font-medium flex-wrap">
              {rowData.product_codes.map(({sku}) => (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full bg-blue-100 text-blue-800" key={`${rowIndex}-${sku}`}>{sku}</span>
              ))}
            </div>
            <div>{rowData.product.bar_code}</div>
          </div>
        ),
        expected: (
          <div className="flex gap-5">
            <div>{rowData.expected_lot_quantity}</div>
          </div>
        ),
        received: (
          <div className="flex gap-5">
            <div>{stockCount.total}</div>
          </div>
        ),
        received_boxes: (
          <div className="flex gap-5">
            <div>{boxesCount.totalReceived}</div>
            <div className="flex flex-col gap-1 text-xs font-medium">
              <div className="px-2.5 py-0.5 rounded-full bg-gray-100 w-max">
                {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.complete_boxes_plural', {quantity: boxesCount.completeReceived})}
              </div>
              <div className="px-2.5 py-0.5 rounded-full bg-gray-100 w-max">
                {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.uncomplete_boxes_plural', {quantity: boxesCount.uncompleteReceived})}
              </div>
            </div>
          </div>
        ),
        received_pieces: (
          <div className="flex gap-5">
            <div>{stockCount.total}</div>
            <div className="flex flex-col gap-1 text-xs font-medium">
              <div className={`px-2.5 py-0.5 rounded-full w-max ${stockCount.notDamaged > 0 ? "bg-green-100 text-green-700" : "bg-gray-100"}`}>
                {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.not_damaged_pieces_plural', {quantity: stockCount.notDamaged})}
              </div>
              <div className={`px-2.5 py-0.5 rounded-full w-max ${stockCount.damaged > 0 ? "bg-red-100 text-red-700" : "bg-gray-100"}`}>
                {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.damaged_pieces_plural', {quantity: stockCount.damaged})}
              </div>
            </div>
          </div>
        ),
        pieces_status: (
          <div className="flex flex-col gap-2 text-xs font-medium">
            <div className={`px-2.5 py-0.5 rounded-full w-max ${stockCount.notDamaged > 0 ? "bg-green-100 text-green-700" : "bg-gray-100"}`}>
              {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.not_damaged_pieces_plural', {quantity: stockCount.notDamaged})}
            </div>
            <div className={`px-2.5 py-0.5 rounded-full w-max ${stockCount.damaged > 0 ? "bg-red-100 text-red-700" : "bg-gray-100"}`}>
              {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.damaged_pieces_plural', {quantity: stockCount.damaged})}
            </div>

            {stockCount.total === 0 ? (
              <Tooltip title={i18n.t("replenishments.show_replenishment_boxes.received_boxes_table.cannot_edit_damaged")}>
                <button disabled={true} onClick={() => {}} className={"cursor-not-allowed text-gray-400 hover:text-gray-300 text-left"}>
                  {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.fix_DAMAGED_button')}
                </button>
              </Tooltip>
            ) : (
              <button
                onClick={() => setFixQuantityDialog({
                  lotUniqueCode: rowData.lot.unique_code,
                  show: true,
                  rowData: rowData,
                  quantity: stockCount.damaged,
                  maxQuantity: stockCount.damaged,
                  loading: false,
                  mode: FIX_QUANTITY_MODES.DAMAGED
                })}
                className="cursor-pointer text-blue-400 hover:text-blue-300 text-left"
              >
                {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.fix_DAMAGED_button')}
              </button>
            )}
          </div>
        ),
        in_putaway_count: (
          <div className="flex flex-col gap-2 text-xs font-medium">
            {stockCount.putaway}
          </div>
        ),
        in_putaway: (
          <div className="flex flex-col gap-2 text-xs font-medium">
            {stockCount.putaway}

            {stockCount.total === 0 ? (
              <Tooltip title={i18n.t("replenishments.show_replenishment_boxes.received_boxes_table.cannot_edit_quantity")}>
                <button disabled={true} onClick={() => {}} className={"cursor-not-allowed text-gray-400 hover:text-gray-300 text-left"}>
                  {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.fix_PUTAWAY_button')}
                </button>
              </Tooltip>
            ) : (
              <button
                onClick={() => setFixQuantityDialog({
                  lotUniqueCode: rowData.lot.unique_code,
                  show: true,
                  rowData: rowData,
                  quantity: stockCount.putaway,
                  maxQuantity: stockCount.putaway,
                  loading: false,
                  mode: FIX_QUANTITY_MODES.PUTAWAY
                })}
                className="cursor-pointer text-blue-400 hover:text-blue-300 text-left"
              >
                {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.fix_PUTAWAY_button')}
              </button>
            )}
          </div>
        ),
        actions: (
          <div>
            {stockCount.total === 0 ? (
              <Tooltip title={i18n.t("replenishments.show_replenishment_boxes.received_boxes_table.cannot_send_to_damage")}>
                <div>
                  <Button disabled={true} className={"cursor-not-allowed"} type="secondary" onClick={() => {}}>
                    {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.send_to_damage_button')}
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <Button type="secondary" onClick={() => setDamagedPiecesDialog({lotUniqueCode: rowData.lot.unique_code, show: true, rowData: rowData, quantity: 0, maxQuantity: stockCount.putaway, loading: false, errorMsg: null})}>
                {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.send_to_damage_button')}
              </Button>
            )}
          </div>
        ),
      }
    })

    setRows(newRows)
  }, [rowsData, i18n])

  return (
    <section className="flex-1 bg-white">
      {!showFilters && (
        <div className="flex justify-between items-center">
          <div className="flex gap-1 items-center m-5 text-lg font-bold">
            {i18n.t("replenishments.show_replenishment_boxes.last_movements")}
            <RefreshIcon className="h-5 w-5 cursor-pointer hover:text-gray-400" onClick={refetch}/>
          </div>

          <div><FetchingIndicator isFetching={isLoading || isFetching}/></div>
        </div>
      )}

      <NewTable
        data={rows}
        columns={columns.filter( ({accessor}) => !hiddenColumns.includes(accessor)) }
        showLoader={isLoading}
        showPaginationOnFooter={showFilters}
        isFetching={isFetching}
        emptyTableText= {showFilters ? i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.empty_table') : i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.empty_movements')}
        paginationMeta={rowsData.meta ? rowsData.meta : {per_page: 10}}
        onPaginationChange= {page => {
          if(page !== rowsData.meta.page){
            const newSearchParams = {...searchParams, page: page}
            setSearchParams(newSearchParams)
            setIsFetchEnabled(true)
          }
        }}
        onPageSizeChange = {({id: per_page}) => {
          if(per_page !== rowsData.meta?.per_page){
            const newSearchParams = {...searchParams, page: 1, per_page: per_page}
            setSearchParams(newSearchParams)
            setIsFetchEnabled(true)
          }
        }}
        onSortChange={ () => {} }
        onSearchHandler={updateFiltersData}
        handleResetFilters={() => {
          setRowsData({data: [], meta: null})
          setSearchParams({...initialSearchParams})
        }}
        onClickFilterDropdown={ () => {} }
        filtersData={filters}
        hasExport={false}
        rowProps={row => ({
          onClick: () => {}
        })}
        onSelectionChange={ (rows) => {} }
        selectable={false}
        applyFiltersManually={true}
        onManuallyAppliedFilters={() => refetch()}
        cursorPointer={false}
        showFiltersHeader={showFilters}
        footerText={""}
      />

      <DialogView setOpen={()=>{}} open={fixQuantityDialog.show}>
        <h3 className="text-lg leading-6 font-bold mb-10">{i18n.t(`replenishments.show_replenishment_boxes.received_boxes_table.fix_quantity.${fixQuantityDialog.mode}_title`)}</h3>

        <div className="text-base flex flex-col mb-5">
          <p className="font-bold m-0 ">{i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.fix_quantity.product_name')}</p>
          {fixQuantityDialog.rowData?.product.name}
        </div>

        <div className="text-base flex flex-col mb-5 overflow-hidden">
          <p className="font-bold">{i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.fix_quantity.sku')}</p>

          <div className="flex gap-2 flex-wrap">
            {fixQuantityDialog.rowData?.product_codes.map(({sku}) => (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full bg-blue-100 text-blue-800" key={`putaway-pieces-${sku}`}>
                {sku}
              </span>
            ))}
          </div>

          {fixQuantityDialog.rowData?.bar_code}
        </div>

        <div className="text-base flex flex-col mb-5">
          <p className="font-bold">{i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.fix_quantity.lot')}</p>
          {`${fixQuantityDialog.rowData?.lot.unique_code} | ${fixQuantityDialog.rowData?.lot.expiration_date}`}
        </div>

        <p className="text-base font-bold mb-2">{i18n.t(`replenishments.show_replenishment_boxes.received_boxes_table.fix_quantity.${fixQuantityDialog.mode}_description`)}</p>

        <InputQuantity enableInput={true} maxValue={fixQuantityDialog.maxQuantity} initialValue={fixQuantityDialog.quantity} updateData={(quantity) => setFixQuantityDialog({...fixQuantityDialog, quantity: quantity})}/>

        {fixQuantityDialog.errorMsg !== null && <div className="text-lg mt-5 font-bold text-red-500">{fixQuantityDialog.errorMsg}</div>}

        <div className="flex gap-5 mt-5 w-full justify-center">
          <Button type="secondary" onClick={() => setFixQuantityDialog({...fixQuantityDialog, show: false})} disabled={fixQuantityDialog.loading} className={`${fixQuantityDialog.loading ? "cursor-not-allowed" : ""}`}>
            {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.fix_quantity.cancel_button')}
          </Button>

          <Button
            disabled={fixQuantityDialog.quantity === null}
            loading={fixQuantityDialog.loading}
            onClick={()=>handleOnConfirmFixPiecesQuantity(fixQuantityDialog.lotUniqueCode, fixQuantityDialog.quantity, fixQuantityDialog.mode)}
            className={`${fixQuantityDialog.loading ? "cursor-not-allowed" : ""}`}>
            {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.fix_quantity.confirm_button')}
          </Button>
        </div>
      </DialogView>

      <DialogView setOpen={()=>{}} open={damagedPiecesDialog.show} className=" justify-items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-5">
          {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.damaged_pieces_dialog.title')}
        </h3>

        <p className="text-gray-500 text-base flex flex-col text-center mb-5">
          {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.damaged_pieces_dialog.description')}
        </p>

        <InputQuantity maxValue={damagedPiecesDialog.maxQuantity} enableInput={true} initialValue={damagedPiecesDialog.quantity} updateData={(quantity) => setDamagedPiecesDialog({...damagedPiecesDialog, quantity: quantity})}/>

        {damagedPiecesDialog.errorMsg !== null && <div className="text-lg mt-5 font-bold text-red-500">{damagedPiecesDialog.errorMsg}</div>}

        <div className="flex gap-5 mt-5">
          <Button type="secondary" onClick={() => {setDamagedPiecesDialog({...damagedPiecesDialog, show: false})}} disabled={damagedPiecesDialog.loading} className={`${damagedPiecesDialog.loading ? "cursor-not-allowed" : ""}`}>
            {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.damaged_pieces_dialog.cancel_button')}
          </Button>

          <Button
            loading={damagedPiecesDialog.loading}
            onClick={()=>handleOnConfirmDamagedPiecesQuantity(damagedPiecesDialog.lotUniqueCode, damagedPiecesDialog.sku, damagedPiecesDialog.quantity)}
            disabled={damagedPiecesDialog.quantity === 0 || damagedPiecesDialog.quantity === null}
            className={`${damagedPiecesDialog.quantity === 0 || damagedPiecesDialog.loading ? "cursor-not-allowed" : ""}`}
          >
            {i18n.t('replenishments.show_replenishment_boxes.received_boxes_table.damaged_pieces_dialog.confirm_button')}
          </Button>
        </div>
      </DialogView>
    </section>
  )
}
