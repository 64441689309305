import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Layout } from "./Layout"
import { ReceiveProgressBar } from "./ReceiveProgressBar"
import { Button, DialogView, SkeletonLoader, InputQuantity, Checkbox } from "../../components"
import { getBoxReplenishment, getBoxLabelRegex, setBoxReplenishmentQualityCheck, setBoxReplenishmentArrived, startBoxReplenishmentReception, endBoxReplenishmentReception, reopenBoxReplenishmentReception, getBoxReplenishmentProgress } from "../../services"
import { ReceiveBoxes } from "./ReceiveBoxes"
import { PutawayProgressBar } from "./PutawayProgressBar"
import { Breakdown } from "./Breakdown"
import { getQueryErrorData } from "./utils"

const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 60 // 60 minutes
const DEFAULT_PROGRESS_QUERY_STALE_TIME = 1000 * 60 * 60 // 5 minutes

export const ShowReplenishmentBoxes= () => {
  const { i18n } = useTranslation()
  const { id: replenishmentId } = useParams()

  const [showBreakdown, setShowBreakdown] = useState(false)
  const [receiveCompleteBoxes, setReceiveCompleteBoxes] = useState(null)
  const [openConfirmReplenishmentDialog, setOpenConfirmReplenishmentDialog] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [qualityCheckData, setQualityCheckData] = useState({numberOfBoxes: 1, productIsSegregated: false, productIsLabeled: false, clientAttendedAppointment: false, isUpdating: false})
  const [isFetching, setIsFetching] = useState(false)
  const [headerErrMsg, setHeaderErrMsg] = useState(null)

  const STATUS_WITHOUT_SCHEDULING = "WITHOUT_SCHEDULING"
  const STATUS_REQUESTED = "REQUESTED"
  const STATUS_IN_PUTAWAY = "IN_PUTAWAY"
  const STATUS_COMPLETED = "COMPLETED"
  const STATUS_RECEIVED = "RECEIVED"
  const STATUS_PROCESSING = "PROCESSING"

  const {
    data: dataBoxReplenishment,
    refetch: refetchBoxReplenishment
  } = useQuery(['box_replenishment', replenishmentId], () => getBoxReplenishment(replenishmentId), {
    staleTime: DEFAULT_QUERY_STALE_TIME
  })

  const {
    data: dataBoxLabelRegex
  } = useQuery(['box_label_regex', replenishmentId], () => getBoxLabelRegex(replenishmentId), {
    staleTime: DEFAULT_QUERY_STALE_TIME
  })

  const {
    data: dataProgress,
    refetch: refetchProgress,
    isFetching: isFetchingProgess,
    isLoading: isLoadingProgress
  } = useQuery(['box_replenishment_progress', replenishmentId], () => getBoxReplenishmentProgress(replenishmentId), {
    staleTime: DEFAULT_PROGRESS_QUERY_STALE_TIME
  })

  const handleOnCompleteReceive = async () => {
    setHeaderErrMsg(null)
    setIsFetching(true)

    let success = true
    let errorData = null

    try{
      await endBoxReplenishmentReception(replenishmentId)
      //await new Promise((resolve) => setTimeout(resolve, 3000))
    }catch(error){
      errorData = getQueryErrorData(error)
      success = false
    }

    if(!success){
      setHeaderErrMsg(i18n.t("replenishments.show_replenishment_boxes.errors.complete_receive_error", {error_data: JSON.stringify(errorData)}))
    }else{
      refetchBoxReplenishment()
    }

    setIsFetching(false)
    setOpenConfirmReplenishmentDialog(false)
  }

  const handleOnReopen = async () => {
    setHeaderErrMsg(null)
    setIsFetching(true)

    let success = true
    let errorData = null

    try{
      await reopenBoxReplenishmentReception(replenishmentId)
      //await new Promise((resolve) => setTimeout(resolve, 3000))
    }catch(error){
      errorData = getQueryErrorData(error)
      success = false
    }

    if(!success){
      setHeaderErrMsg(i18n.t("replenishments.show_replenishment_boxes.errors.complete_putaway_error", {error_data: JSON.stringify(errorData)}))
    }else{
      refetchBoxReplenishment()
    }

    setIsFetching(false)
  }

  const handleOnUpdateQualityCheck = async ({ numberOfBoxes, productIsSegregated, productIsLabeled, clientAttendedAppointment }) => {
    setErrorMsg(null)
    setIsFetching(true)

    let success = true
    let errorData = null

    const params = { attended_appointment: clientAttendedAppointment, segregated_product: productIsSegregated, labeled_product: productIsLabeled }

    if(numberOfBoxes > 0){
      params.number_of_boxes = numberOfBoxes
    }

    try{
      await setBoxReplenishmentQualityCheck(dataBoxReplenishment.id, params)
      setQualityCheckData({ numberOfBoxes, productIsSegregated, productIsLabeled, clientAttendedAppointment })
      //await new Promise((resolve) => setTimeout(resolve, 5000))
    }catch(error){
      errorData = getQueryErrorData(error)
      success = false
    }

    if(!success){
      const errMsg = i18n.t("replenishments.show_replenishment_boxes.errors.quality_check_error", {error_data: JSON.stringify(errorData)})
      setErrorMsg(errMsg)
    }

    setIsFetching(false)
  }

  const handleOnSetArrived = async ({ numberOfBoxes, productIsSegregated, productIsLabeled, clientAttendedAppointment }) => {
    setErrorMsg(null)
    setIsFetching(true)

    let success = true
    let errorData = null

    const params = {
      number_of_boxes: numberOfBoxes,
      attended_appointment: [true, false].includes(clientAttendedAppointment) ? clientAttendedAppointment : false,
      segregated_product: productIsSegregated,
      labeled_product: productIsLabeled
    }

    try{
      await setBoxReplenishmentArrived(dataBoxReplenishment.id, params)
      setQualityCheckData({ numberOfBoxes, productIsSegregated, productIsLabeled, clientAttendedAppointment })
      refetchBoxReplenishment()
      //await new Promise((resolve) => setTimeout(resolve, 5000))
    }catch(error){
      errorData = getQueryErrorData(error)
      success = false
    }

    if(!success){
      const errMsg = i18n.t("replenishments.show_replenishment_boxes.errors.quality_check_error", {error_data: JSON.stringify(errorData)})
      setErrorMsg(errMsg)
    }

    setIsFetching(false)
  }

  const handleOnReceive = async ({ numberOfBoxes, productIsSegregated, productIsLabeled, clientAttendedAppointment }) => {
    setErrorMsg(null)
    setIsFetching(true)

    let success = true
    let errorData = null

    const params = { number_of_boxes: numberOfBoxes, attended_appointment: clientAttendedAppointment, segregated_product: productIsSegregated, labeled_product: productIsLabeled }

    try{
      await startBoxReplenishmentReception(dataBoxReplenishment.id, params)
      setQualityCheckData({ numberOfBoxes, productIsSegregated, productIsLabeled, clientAttendedAppointment })
      refetchBoxReplenishment()
      //await new Promise((resolve) => setTimeout(resolve, 5000))
    }catch(error){
      errorData = getQueryErrorData(error)
      success = false
    }

    if(!success){
      const errMsg = i18n.t("replenishments.show_replenishment_boxes.errors.start_error", {error_data: JSON.stringify(errorData)})
      setErrorMsg(errMsg)
    }

    setIsFetching(false)
  }

  useEffect(() => {
    if(dataBoxReplenishment){
      setQualityCheckData({
        numberOfBoxes: dataBoxReplenishment.number_of_boxes,
        productIsSegregated: dataBoxReplenishment.segregated_product,
        productIsLabeled: dataBoxReplenishment.labeled_product,
        clientAttendedAppointment: dataBoxReplenishment.attended_appointment
      })
    }
  }, [dataBoxReplenishment])

  return (
    <div>
      <Layout
        replenishment={dataBoxReplenishment}
        topMenuComponent={
          receiveCompleteBoxes === null && !showBreakdown && (
            <div className="flex flex-col gap-1">
              <div className="flex gap-5 justify-end">
                  {["PROCESSING"].includes(dataBoxReplenishment?.status) && (
                    <Button
                      onClick={async () => {
                        if(dataProgress.total_expected_stock_count !== dataProgress.total_check_in_stock_count){
                          setOpenConfirmReplenishmentDialog(true)
                        } else{
                          await handleOnCompleteReceive()
                        }
                      }}
                      disabled={isFetching}
                    >
                      {i18n.t('replenishments.show_replenishment_boxes.complete_receive_button')}
                    </Button>
                  )}

                  {/* {!dataBoxReplenishment.is_from_return && ["IN_PUTAWAY"].includes(dataBoxReplenishment?.status) && (
                    <Button onClick={() => ()} disabled={isFetching}>
                      {i18n.t('replenishments.show_replenishment_boxes.complete_putaway_button')}
                    </Button>
                  )} */}

                  {["IN_PUTAWAY"].includes(dataBoxReplenishment?.status) && (
                    <Button onClick={() => handleOnReopen()} disabled={isFetching}>
                      {i18n.t('replenishments.show_replenishment_boxes.reopen_button')}
                    </Button>
                  )}
              </div>

              {headerErrMsg && <div className="font-bold text-red-500 text-xs">{headerErrMsg}</div>}
            </div>
          )
        }
      >
        {!showBreakdown && [STATUS_WITHOUT_SCHEDULING, STATUS_REQUESTED].includes(dataBoxReplenishment?.status) && (
          <section className="flex-1 bg-white p-5">
            <h1 className="text-lg mt-5 mb-5 leading-6 font-medium text-gray-900">{i18n.t('replenishments.show_replenishment.inventory_receipt')}</h1>

            <label className="block text-base font-medium text-gray-700" id="headlessui-listbox-label-3">{i18n.t('replenishments.show_replenishment.how_many_boxes_in_receipt')}</label>
            <div className="w-24 my-5" disabled={isFetching}>
                <InputQuantity enableInput={true} initialValue={qualityCheckData.numberOfBoxes} updateData={value => setQualityCheckData({...qualityCheckData, numberOfBoxes: value})} />
            </div>

            <label className="block text-base font-medium text-gray-700" id="headlessui-listbox-label-3">{i18n.t('replenishments.show_replenishment.attended_appointment')}</label>
            <div className="mt-1">
              <Checkbox
                label={i18n.t('replenishments.show_replenishment.attended_appointment')}
                onChange={isChecked => setQualityCheckData({...qualityCheckData, clientAttendedAppointment: isChecked})}
                checked={qualityCheckData.clientAttendedAppointment}
                disabled={isFetching || !qualityCheckData.numberOfBoxes || qualityCheckData.numberOfBoxes <= 0}
              />
            </div>

            <Button className="mt-5 mr-1" loading={false} onClick={() => handleOnSetArrived(qualityCheckData)} disabled={isFetching || !qualityCheckData.numberOfBoxes || qualityCheckData.numberOfBoxes <= 0}>
                {i18n.t('replenishments.show_replenishment.mark_as_received')}
            </Button>

            {errorMsg && <div className="font-bold text-red-500 mt-1">{errorMsg}</div>}
          </section>
        )}

        {!showBreakdown && [STATUS_IN_PUTAWAY, STATUS_COMPLETED].includes(dataBoxReplenishment?.status) && (
          <section className="flex-1 bg-white p-5">
            <h1 className="text-lg my-5 leading-6 font-medium text-gray-900">
              {dataBoxReplenishment?.status === STATUS_IN_PUTAWAY ? i18n.t('replenishments.show_replenishment_boxes.in_putaway') : i18n.t('replenishments.show_replenishment_boxes.completed')}
            </h1>
            { !dataBoxReplenishment?.is_from_return && (
                <>
                    <label className="block text-base font-medium text-gray-700" id="headlessui-listbox-label-3">Quality check</label>
                    <div className="mt-1">
                        <Checkbox
                            label={i18n.t('replenishments.show_replenishment.segregated_check')}
                            onChange={isChecked =>  handleOnUpdateQualityCheck({...qualityCheckData, productIsSegregated: isChecked})}
                            checked={qualityCheckData.productIsSegregated}
                            disabled={isFetching}
                        />
                        <Checkbox
                            label={i18n.t('replenishments.show_replenishment.labeled_check')}
                            onChange={isChecked => handleOnUpdateQualityCheck({...qualityCheckData, productIsLabeled: isChecked})}
                            checked={qualityCheckData.productIsLabeled}
                            disabled={isFetching}
                        />
                    </div>

                    {errorMsg && <div className="font-bold text-red-500 mt-1">{errorMsg}</div>}
                </>
            )}
          </section>
        )}

        {!showBreakdown && [STATUS_RECEIVED].includes(dataBoxReplenishment?.status) && (
          <section className="flex-1 bg-white p-5">
            <h1 className="text-lg mt-5 mb-5 leading-6 font-medium text-gray-900">{i18n.t('replenishments.show_replenishment_boxes.start_check_in_title')}</h1>

            <Button className="my-1" loading={isFetching} onClick={() => handleOnReceive({...qualityCheckData})} disabled={isFetching}>
              {i18n.t('replenishments.show_replenishment_boxes.start_chcek_in_button')}
            </Button>
          </section>
        )}

        {[STATUS_PROCESSING].includes(dataBoxReplenishment?.status) && (
          <>
            {receiveCompleteBoxes === null && !showBreakdown && (
              <div className="flex flex-col gap-5">
                <section className="flex-1 bg-white p-5">
                  <SkeletonLoader isLoading={!dataBoxReplenishment || !dataBoxLabelRegex}>
                    <h3 className="text-xl leading-6 font-medium text-gray-900 mb-5">
                      {i18n.t('replenishments.show_replenishment_boxes.receive_options.title')}
                    </h3>

                    <div className="flex gap-5">
                      <button className="border-2 p-2 w-full" onClick={() => setReceiveCompleteBoxes(true)}>
                        {i18n.t('replenishments.show_replenishment_boxes.receive_options.options.complete_boxes')}
                      </button>

                      <button className="border-2 p-2 w-full" onClick={() => setReceiveCompleteBoxes(false)}>
                        {i18n.t('replenishments.show_replenishment_boxes.receive_options.options.uncomplete_boxes')}
                      </button>
                    </div>
                  </SkeletonLoader>
                </section>
              </div>
            )}

            {receiveCompleteBoxes !== null && (
              <ReceiveBoxes
                replenishment={dataBoxReplenishment}
                receiveCompleteBoxes={receiveCompleteBoxes}
                onClickReturnPreviousPage={() => setReceiveCompleteBoxes(null)}
                boxLabelPatterns={dataBoxLabelRegex?.replenishment_box_label_pattern}
              />
            )}

          </>
        )}

        <ReceiveProgressBar
          totalExpected={dataProgress?.total_expected_stock_count}
          totalReceived={dataProgress?.total_check_in_stock_count}
          onShowBreakDown={() => setShowBreakdown(true)}
          isLoadingInitialData={!dataProgress}
          isLoading={isLoadingProgress || isFetchingProgess}
          className={"mt-5"}
          show={!showBreakdown && receiveCompleteBoxes === null}
          replenishment={dataBoxReplenishment}
          onRefetch={refetchProgress}
        />

        <PutawayProgressBar
          totalCheckIn={dataProgress?.total_check_in_stock_count}
          totalPutaway={dataProgress?.total_putaway_stock_count}
          onShowBreakDown={() => setShowBreakdown(true)}
          isLoadingInitialData={!dataProgress}
          isLoading={isLoadingProgress || isFetchingProgess}
          className={"mt-5"}
          show={!showBreakdown && receiveCompleteBoxes === null}
          replenishment={dataBoxReplenishment}
          onRefetch={refetchProgress}
        />

        <Breakdown
          replenishment={dataBoxReplenishment}
          onClickReturnPreviousPage={() => {setShowBreakdown(false)}}
          boxLabelPatterns={dataBoxLabelRegex?.replenishment_box_label_pattern}
          show={showBreakdown}
        />

      </Layout>

      <DialogView setOpen={setOpenConfirmReplenishmentDialog} open={openConfirmReplenishmentDialog} className=" justify-items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {i18n.t('replenishments.show_replenishment_boxes.complete_replenishment_dialog.received_less_than_expected_title')}
        </h3>

        <p className="text-gray-500 text-base flex flex-col text-center mb-5">
          {i18n.t('replenishments.show_replenishment_boxes.complete_replenishment_dialog.received_less_than_expected_description')}

          <span className="font-bold">
            {i18n.t('replenishments.show_replenishment_boxes.complete_replenishment_dialog.received_less_than_expected_confirm_question')}
          </span>
        </p>

        <div className="flex gap-5">
          <Button type="secondary" onClick={() => setOpenConfirmReplenishmentDialog(false)} disabled={isFetching}>
            {i18n.t('replenishments.show_replenishment_boxes.complete_replenishment_dialog.cancel_button')}
          </Button>

          <Button loading={isFetching} onClick={handleOnCompleteReceive}>
            {i18n.t('replenishments.show_replenishment_boxes.complete_replenishment_dialog.confirm_button')}
          </Button>
        </div>
      </DialogView>
    </div>
  )
}
