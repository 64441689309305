import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RefreshIcon } from "@heroicons/react/outline"

import { SkeletonLoader } from "../../components/SkeletonLoader"
import { FetchingIndicator } from "../../components/BasicTable/modules/FetchingIndicator"

export const ReceiveProgressBar = ({totalExpected, totalReceived, isLoadingInitialData=false, isLoading=false, onShowBreakDown ,className, show=true, onRefetch}) => {
  const { i18n } = useTranslation()
  const [remainingPieces, setRemainingPieces] = useState(null)
  const [completedPercentage, setCompletedPercentage] = useState(null)

  useEffect(() => {
    if(Number.isInteger(totalExpected) && Number.isInteger(totalReceived)){
      const newRemainingPieces = totalExpected - totalReceived
      const newCompletedPercentage = totalReceived === 0 && totalExpected === 0 ? 100 : parseFloat(((totalReceived * 100) / totalExpected).toFixed(2))

      setRemainingPieces(newRemainingPieces)
      setCompletedPercentage(newCompletedPercentage)
    }
  }, [totalExpected, totalReceived])

  return (
    show ? (
      <section className={`flex-1 bg-white p-5 ${className}`}>
        <SkeletonLoader isLoading={isLoadingInitialData}>
          <h3 className="text-lg font-bold mb-4 flex justify-between">
            <div className="flex gap-1 items-center">
              {i18n.t("replenishments.show_replenishment_boxes.receive_progress_bar.title")}
              <RefreshIcon className="h-5 w-5 cursor-pointer hover:text-gray-400" onClick={onRefetch}/>
            </div>
            <div className="w-1 h-1"><FetchingIndicator isFetching={isLoading}/></div>
          </h3>

          <div className="flex items-center gap-2">
            <progress className="w-full" value={completedPercentage} max={"100"} />
            <span className="text-green-600 font-bold">{`${completedPercentage}%`}</span>
          </div>

          <div className="flex justify-between">
            <div className="flex gap-1">
              <span className="font-bold">
                {i18n.t("replenishments.show_replenishment_boxes.receive_progress_bar.remaining_pieces",{quantity: remainingPieces})}
              </span>
              |
              {/* <span>
                {i18n.t("replenishments.show_replenishment_boxes.receive_progress_bar.received_boxes",{complete_boxes: receivedCompletedBoxes, uncomplete_boxes: receivedUncompletedBoxes})}
              </span> */}
              <button className="text-blue-400 hover:text-blue-300" onClick={onShowBreakDown}>
                {i18n.t("replenishments.show_replenishment_boxes.receive_progress_bar.show_breakdown")}
              </button>
            </div>

            <div className="font-bold text-green-600">
              {i18n.t("replenishments.show_replenishment_boxes.receive_progress_bar.general_quantity",{received_pieces: totalReceived, expected_pieces: totalExpected})}
            </div>
          </div>
        </SkeletonLoader>
      </section>
    ) : <></>
  )
}
