import { useTranslation } from "react-i18next"

import { PageView, FilterMenu } from "../../components"

export const Layout = ({replenishment, topMenuComponent=<></>, children}) => {
  const { i18n } = useTranslation()

  return (
    <PageView
      topMenu={
        replenishment && (
          <FilterMenu>
              <div>
                  <h1 className="text-lg leading-4 font-medium text-gray-900">
                    {i18n.t('replenishments.show_replenishment.title') + " " + replenishment.id}
                  </h1>

                  <h3 className="text-sm leading-3 font-normal text-gray-500">
                    {replenishment.sell_ahead ? i18n.t('replenishments.show_replenishment.sell_ahead') : i18n.t('replenishments.show_replenishment.not_sell_ahead')}
                  </h3>
              </div>

              {topMenuComponent ? topMenuComponent : <></>}
          </FilterMenu>
        )
      }
    >
      {children}
    </PageView>
  )
}
