import { useEffect, useRef, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { XIcon } from '@heroicons/react/solid'
import { ArrowLeftIcon } from "@heroicons/react/outline"

import { ReceivedBoxesTable, COLUMNS_ACCESORS } from "./ReceivedBoxesTable"
import { SearchInput, DialogView, Button } from "../../components"
import { getBarcodeRegexMatch, buildLotUniqueCode, getQueryErrorData } from "./utils"
import { getInventoryHistory, receiveBoxReplenishment } from "../../services"
import { FetchingIndicator } from "../../components/BasicTable/modules/FetchingIndicator"
import { UserContext } from "../../hooks/UserContext"

export const ReceiveBoxes = ({replenishment, receiveCompleteBoxes, onClickReturnPreviousPage, boxLabelPatterns}) => {
  const { i18n } = useTranslation()
  const { user } = useContext(UserContext)

  const scanBarcodeInputRef = useRef(null)
  const scanQuantityBarcodeInputRef = useRef(null)

  const [boxQuantityDialog, setBoxQuantityDialog] = useState({show: false, unique_code: "", sku: "", quantity: null, errMsg: null})
  const [scannedBarcodeErrMsg, setScannedBarcodeErrMsg] = useState(null)
  const [lastReceivedBox, setLastReceivedBox] = useState({uniqueCode: "", quantity: null})
  const [isReceiving, setIsReceiving] = useState(false)

  const hiddenColumns = [
    COLUMNS_ACCESORS.SCANNED_BOX,
    COLUMNS_ACCESORS.EXPECTED,
    COLUMNS_ACCESORS.RECEIVED,
    COLUMNS_ACCESORS.PIECES_STATUS,
    COLUMNS_ACCESORS.IN_PUTAWAY,
    COLUMNS_ACCESORS.IN_PUTAWAY_COUNT,
    COLUMNS_ACCESORS.RECEIVED_BOXES
  ]

  const receiveBox = async (lotUniqueCode, sku, quantity) => {
    let success = true
    let errorData = null
    const params = { lot_unique_code: buildLotUniqueCode(sku, lotUniqueCode), sku: sku, quantity: quantity }

    try{
      await receiveBoxReplenishment(replenishment.id, params)
      //await new Promise((resolve) => setTimeout(resolve, 5000))
    }catch(error){
      errorData = getQueryErrorData(error)
      success = false
    }

    setLastReceivedBox({uniqueCode: lotUniqueCode, quantity: quantity})
    return { success: success, errorData: errorData }
  }

  const handleOnScannedBarcode = async (barcode) => {
    if(barcode === ""){
      return
    }
    scanBarcodeInputRef.current.setIsSearchDisabled(true)
    scanBarcodeInputRef.current.resetInput()
    setScannedBarcodeErrMsg(null)
    setIsReceiving(true)

    const regexMatch = getBarcodeRegexMatch(boxLabelPatterns, barcode)
    let errMsg = regexMatch ? null : i18n.t("replenishments.show_replenishment_boxes.errors.invalid_barcode", {barcode: barcode})
    const { lot_unique_code = null, product_code = null } = regexMatch?.groups || {}

    if(!errMsg && !receiveCompleteBoxes){
      setBoxQuantityDialog({show: true, lotUniqueCode: lot_unique_code, sku: product_code, quantity: null})
    } else if(!errMsg){
      const result = await receiveBox(lot_unique_code, product_code)

      if(!result.success){
        errMsg = i18n.t("replenishments.show_replenishment_boxes.errors.receive_error", {error_data: JSON.stringify(result.errorData)})
      }
    }

    scanBarcodeInputRef.current.setIsSearchDisabled(false)
    scanBarcodeInputRef.current.focus()
    setScannedBarcodeErrMsg(errMsg)
    setIsReceiving(false)
  }

  const handleOnScannedQuantityBarcode = (barcode) => {
    if(barcode === ""){
      return
    }

    const isValid = /^\d+$/.test(barcode)
    const errMsg = isValid ? null : i18n.t("replenishments.show_replenishment_boxes.errors.invalid_barcode", {barcode: barcode})
    setBoxQuantityDialog({...boxQuantityDialog, quantity: parseInt(barcode), errMsg: errMsg})
  }

  const handleOnConfirmQuantity = async () => {
    scanQuantityBarcodeInputRef.current?.resetInput()
    setIsReceiving(true)

    let errMsg = null
    const result = await receiveBox(boxQuantityDialog.lotUniqueCode, boxQuantityDialog.sku, boxQuantityDialog.quantity)

    if(!result.success){
      errMsg = i18n.t("replenishments.show_replenishment_boxes.errors.receive_error", {error_data: JSON.stringify(result.errorData)})
      setBoxQuantityDialog({...boxQuantityDialog, errMsg: errMsg})
    }else{
      setBoxQuantityDialog({...boxQuantityDialog, errMsg: errMsg, show: false})
    }

    setIsReceiving(false)
  }

  useEffect(() => {
    scanBarcodeInputRef.current?.focus()
  }, [])

  return (
    <div>
      <div className="flex flex-col gap-5">
        <section className="flex-1 bg-white">
            <div className={`${receiveCompleteBoxes ? "bg-secondary-orange-50" : "bg-gray-900"} text-white p-5 text-xl`}>
              <button onClick={onClickReturnPreviousPage} className="flex gap-1 items-center">
                <ArrowLeftIcon className="h-5 w-5"/>
                {i18n.t('replenishments.show_replenishment_boxes.receive_options.receiving_title')}
                <span className="font-bold">
                  {receiveCompleteBoxes ? (
                    i18n.t('replenishments.show_replenishment_boxes.receive_options.options.complete_boxes')
                  ) : (
                    i18n.t('replenishments.show_replenishment_boxes.receive_options.options.uncomplete_boxes')
                  )}
                </span>
              </button>
            </div>

            <div className="p-5">
              <div className="flex gap-1 items-center">
                <div className="font-bold text-lg">{i18n.t('replenishments.show_replenishment_boxes.receive_options.scan_input_title')}</div>
                <div><FetchingIndicator isFetching={isReceiving}/></div>
              </div>

              <SearchInput
                debounce="10"
                dependencies={[]}
                inputValue={""}
                className="w-full my-2"
                onChange={handleOnScannedBarcode}
                ref={scanBarcodeInputRef}
                placeholder={isReceiving ? i18n.t('replenishments.show_replenishment_boxes.receive_options.scan_input_receing')
                  : i18n.t('replenishments.show_replenishment_boxes.receive_options.scan_input_placeholder')
                }
              />

              {scannedBarcodeErrMsg && <div className="font-bold text-red-500">{scannedBarcodeErrMsg}</div>}
            </div>

        </section>

        <ReceivedBoxesTable
          replenishment={replenishment}
          boxLabelPatterns={boxLabelPatterns}
          queryFunction={getInventoryHistory}
          queryDataKey={"inventory_history"}
          showFilters={false}
          hiddenColumns={hiddenColumns}
          queryId={`receive-boxes-${receiveCompleteBoxes ? "complete" : "uncomplete"}`}
          receivedBox={lastReceivedBox}
          additionalSearchParams={{user_id: user?.id, history_action: ["Check-in"]}}
        />

      </div>

      <DialogView setOpen={(show) => setBoxQuantityDialog({...boxQuantityDialog, show: show})} open={boxQuantityDialog.show} className=" justify-items-start">
        <div className="w-full flex justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {i18n.t('replenishments.show_replenishment_boxes.scan_quantity_barcode_dialog.title')}
          </h3>

          <XIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" onClick={() => setBoxQuantityDialog({...boxQuantityDialog, show: false})} disabled={isReceiving} />
        </div>

        <p>{i18n.t('replenishments.show_replenishment_boxes.scan_quantity_barcode_dialog.description')}</p>

        <div className="flex gap-5 w-full items-center">
          <SearchInput
            debounce="0"
            dependencies={[]}
            inputValue={""}
            className="w-full my-2"
            onChange={handleOnScannedQuantityBarcode}
            ref={scanQuantityBarcodeInputRef}
            placeholder={i18n.t('replenishments.show_replenishment_boxes.scan_quantity_barcode_dialog.scan_input_placeholder')}
          />

          <Button
            onClick={handleOnConfirmQuantity}
            disabled={!!boxQuantityDialog.errMsg || !boxQuantityDialog.quantity || isReceiving}
            className={`${!!boxQuantityDialog.errMsg || !boxQuantityDialog.quantity ? "cursor-not-allowed" : ""}`}
          >
            {i18n.t('replenishments.show_replenishment_boxes.scan_quantity_barcode_dialog.confirm_button')}
          </Button>
        </div>

        {boxQuantityDialog.errMsg && <div className="font-bold text-red-500 w-full">{boxQuantityDialog.errMsg}</div>}
      </DialogView>
    </div>
  )
}
