import {useHistory, Link,useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {fetchReplenishments, getBoxLabelRegex} from "../../services";
import { StatusPill } from '../../components/BasicTable/StatusPill'
import { REPLENISHMENT_STATUSES } from '../../components/BasicTable/StatusPillDefinitions';
import {BasicTable, FormattedDate, PageView, SlidePanel, DialogView} from "../../components";
import { useRef, useState,useContext, useEffect } from "react";
import { SelectColumnMultiFilter } from "../../components/BasicTable/modules/ColumnFiltersUI";
import { Tooltip } from "antd";
import {UserContext} from "../../hooks/UserContext";
import {SHOW_REPLENISHMENT, SHOW_REPLENISHMENT_BOXES} from '../../navigation/constants'
import { setReplenishmentsListPageSize, setReturnsListPageSize } from '../../redux/pageSizeSlice';
import { useDispatch, useSelector } from "react-redux";
import { GenerateReplenishmentLabel } from "./GenerateReplenishmentLabel"
import { useTranslation } from "react-i18next";
const STATUS = {
    "PENDING": "Configurando",
    "ARRIVED": "En almacén",
    "IN_TRANSIT": "En Tránsito",
    "PROCESSING": "Procesando",
    "IN_PUTAWAY": "En Put Away",
    "RECEIVED": "Recibido en Almacén"
}


export const ListActiveReplenishments = () => {
    const history = useHistory()
    const tableRef = useRef()
    const returnTableRef = useRef()
    const {user} = useContext(UserContext)
    const {warehouse_id} = useParams()
    const [replenishmentInfo, setReplenishmentInfo] = useState(null)
    const dispatch = useDispatch()
    const pageSize = useSelector((state) => state.pageSize.inventoryHistoryList)
    const defaultSearchParams = {
        page: 1,
        per_page: pageSize,
        non_returns_only: true,
        status: ['IN_TRANSIT', 'REQUESTED', 'PROCESSING', 'COMPLETED', 'IN_PUTAWAY', 'WITHOUT_SCHEDULING', 'RECEIVED'],
        warehouse_id: isNaN(parseInt(warehouse_id)) ? null : parseInt(warehouse_id),
    }
    const {i18n} = useTranslation()
    const returnsPageSize = useSelector((state) => state.pageSize.inventoryHistoryList)
    const defaultReturnSearchParams = {
        page: 1,
        per_page: returnsPageSize,
        return_only: true,
        status: ['IN_PUTAWAY', 'COMPLETED'],
        warehouse_id: isNaN(parseInt(warehouse_id)) ? null : parseInt(warehouse_id),
    }

    const columns = [
        {
            Header: i18n.t('list_active_replenishments.headers.id'),
            accessor: 'id',
            disableFilters: false,
            disableSortBy: false,
            shrinkToContent: true,
            numericOnlyFilter: true
        },
        {
            Header: i18n.t('list_active_replenishments.headers.store'),
            accessor: 'store',
            disableFilters: false,
            disableSortBy: false,
        },
        {
            Header: i18n.t('list_active_replenishments.headers.notes'),
            accessor: 'notes', // accessor is the "key" in the data
            disableFilters: false,
            disableSortBy: false,
        },
        {
            Header: i18n.t('list_active_replenishments.headers.status'),
            accessor: 'status', // accessor is the "key" in the data
            disableFilters: false,
            disableSortBy: false,
            Filter: SelectColumnMultiFilter,
            selectFilterOptions: REPLENISHMENT_STATUSES.filter(
                (status) => status.filterable !== false
            ).map((status) => {
                return status.filterable === false
                    ? null
                    : { id: status.status, label: status.label }
            }),
        },
        {
            Header: i18n.t('list_active_replenishments.headers.sell_ahead'),
            accessor: 'sell_ahead', // accessor is the "key" in the data
            disableFilters: true,
            disableSortBy: false,
        },
        {
            Header: i18n.t('list_active_replenishments.headers.has_expirations'),
            accessor: 'has_expirations', // accessor is the "key" in the data
            disableFilters: true,
            disableSortBy: true,
        },
        // {
        //     Header: 'Fecha creación',
        //     accessor: 'created_at', // accessor is the "key" in the data
        //     disableFilters: true,
        //     disableSortBy: false,
        // },
        {
            Header: i18n.t('list_active_replenishments.headers.actions'),
            accessor: 'actions', // accessor is the "key" in the data
            disableFilters: true,
            disableSortBy: true,
            fetchingIndicator: true,
            shrinkToContent: true,
        },
    ]
    

    const [searchParams, setSearchParams] = useState({...defaultSearchParams})
    const { data, status, isLoading, isError, isFetching, error, isPreviousData } = useQuery(["replenishments", searchParams], () => fetchReplenishments(searchParams), { keepPreviousData: true })
    const [openDialogLabel, setOpenDialogLabel] = useState(false)

    const [searchReturnParams, setReturnSearchParams] = useState({...defaultReturnSearchParams})

    const { data: returnData, status: returnStatus, isLoading: returnIsLoading, isError: returnIsError, isFetching: returnIsFetching, error: returnError, isPreviousData: returnIsPreviousData } = useQuery(["return_replenishments", searchReturnParams], () => fetchReplenishments(searchReturnParams), { keepPreviousData: true })


    const onSortChange = (orderBy) => {
        let newSearchParams = {...searchParams}
        if (orderBy.length > 0) {
            setSearchParams({
                ...newSearchParams,
                order_by: orderBy[0].id,
                order: orderBy[0].desc ? "desc" : "asc",
            })
        } else {
            if ("order_by" in newSearchParams) {
                delete newSearchParams.order_by
                delete newSearchParams.order
                setSearchParams({ ...newSearchParams })
            }
        }
    }

    const onReturnSortChange = (orderBy) => {
        let newSearchReturnParams = {...searchReturnParams}
        if (orderBy.length > 0) {
            setReturnSearchParams({
                ...newSearchReturnParams,
                order_by: orderBy[0].id,
                order: orderBy[0].desc ? "desc" : "asc",
            })
        } else {
            if ("order_by" in newSearchReturnParams) {
                delete newSearchReturnParams.order_by
                delete newSearchReturnParams.order
                setReturnSearchParams({ ...newSearchReturnParams })
            }
        }
    }

    const onFilterChange = (activeFilters) => {
        columns.forEach(col => {
            if (searchParams[col.accessor]) {
                delete searchParams[col.accessor]
            }
        })
        let filters = []
        activeFilters.forEach(filter => {
            filters[filter.id] = filter.value
        })
        if (!('status' in filters) || filters.status.length === 0) {
            filters.status = []
            filters.status.push(...defaultSearchParams.status)
        }
        tableRef.current.resetSelection()
        tableRef.current.resetPagination()
        setSearchParams({...searchParams, ...filters, page:1})
    }

    const onReturnFilterChange = (activeFilters) => {
        columns.forEach(col => {
            if (searchReturnParams[col.accessor]) {
                delete searchReturnParams[col.accessor]
            }
        })
        let filters = []
        activeFilters.forEach(filter => {
            filters[filter.id] = filter.value
        })
        if (!('status' in filters) || filters.status.length === 0) {
            filters.status = []
            filters.status.push(...defaultReturnSearchParams.status)
        }
        returnTableRef.current.resetSelection()
        returnTableRef.current.resetPagination()
        setReturnSearchParams({...searchReturnParams, ...filters, page:1})
    }

    useEffect(() => {
        if ( replenishmentInfo !== null) {
          setOpenDialogLabel(true);
        }
      }, [replenishmentInfo]);
      
    return (
        <PageView>
            {isLoading ? (
                <>Loading...</>
            ) : isError ? (
                <>Error: {error.message}</>
            ) : user?.permissions?.index_replenishments ? (
                <>
                <h1 className="text-lg font-semibold">{i18n.t('list_active_replenishments.replenishments')}</h1>
                <BasicTable
                    ref={tableRef}
                    showHeader
                    columns={columns}
                    labelToPaginator={i18n.t('list_active_replenishments.replenishments')}
                    pagesSize = {searchParams.per_page}
                    onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                    onPageSizeChange= { (pageSize) => {
                        setSearchParams({...searchParams, per_page: pageSize, page: 1})
                        dispatch(setReplenishmentsListPageSize(pageSize))
                        tableRef.current.resetPagination()
                    }}
                    showPaginator
                    isFetching={isFetching}
                    showLoader={isFetching && isPreviousData}
                    paginationMeta={data?.meta}
                    emptyTableText={i18n.t('list_active_replenishments.empty_table_text')}
                    filterable
                    sortable
                    onFilterChange={(activeFilters) => onFilterChange(activeFilters)}
                    onSortChange={(orderBy) => onSortChange(orderBy)}
                    showDataStatus
                    data={data.replenishments.map(replenishment => {
                    return {
                        id: replenishment.id,
                        store: replenishment.store.name,
                        sell_ahead: replenishment.sell_ahead ? 'Si' : 'No',
                        has_expirations: replenishment.has_expirations ? 'Si' : 'No',
                        notes: replenishment.notes?.length > 52 ? <Tooltip title={replenishment.notes}><span>{replenishment.notes?.substring(0, 52)}...</span></Tooltip> : replenishment.notes,
                        // created_at: replenishment.created_at ? <FormattedDate date={replenishment.created_at} shortDate/> : '-',
                        expected_arrived_at: replenishment.expected_arrived_at ? <FormattedDate date={replenishment.expected_arrived_at} shortDate/> : '-',
                        status: (
                            // <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            //     {STATUS[replenishment.status]}
                            // </span>
                            <StatusPill status={replenishment.status} statuses={REPLENISHMENT_STATUSES}/>
                        ),
                        actions: (
                            <button
                                className="text-indigo-600 hover:text-indigo-900"
                                onClick={async () => {
                                    const boxLabelPatterns = await getBoxLabelRegex(replenishment.id)
                                    let replenishmentPath = null

                                    if(boxLabelPatterns?.replenishment_box_label_pattern){
                                        replenishmentPath = SHOW_REPLENISHMENT_BOXES.replace(":id", replenishment.id).replace(":warehouse_id",warehouse_id)
                                    } else{
                                        replenishmentPath = SHOW_REPLENISHMENT.replace(":id", replenishment.id).replace(":warehouse_id",warehouse_id)
                                    }
                                    history.push(replenishmentPath)
                                }}
                            >
                                {i18n.t('list_active_replenishments.receive')}
                            </button>
                        )
                    }
                })}/>
                
                
                </>
                
                ):(
                    <></>
                )}

            {(returnIsLoading ) ? (
                <>Loading...</>
            ) : returnIsError ? (
                <>Error: {error.message}</>
            ) : user?.permissions?.create_and_recieve_returns ? (
                <>
                <h1 className="mt-10 text-lg font-semibold">{i18n.t('list_active_replenishments.reverse_logistics_replenishments')}</h1>
                <BasicTable
                    ref={returnTableRef}
                    showHeader
                    columns={columns}
                    labelToPaginator={i18n.t('list_active_replenishments.replenishments')}
                    pagesSize = {searchReturnParams.per_page}
                    onPaginationChange= { (requestedPage) => { setReturnSearchParams({...searchReturnParams, page: requestedPage}) } }
                    onPageSizeChange= { (pageSize) => {
                        setReturnSearchParams({...searchReturnParams, per_page: pageSize, page: 1})
                        dispatch(setReturnsListPageSize(pageSize))
                        tableRef.current.resetPagination()
                    }}
                    showPaginator
                    isFetching={returnIsFetching}
                    showLoader={returnIsFetching && returnIsPreviousData}
                    paginationMeta={returnData?.meta}
                    emptyTableText={i18n.t('list_active_replenishments.empty_table_text')}
                    filterable
                    sortable
                    onFilterChange={(activeFilters) => onReturnFilterChange(activeFilters)}
                    onSortChange={(orderBy) => onReturnSortChange(orderBy)}
                    showDataStatus
                    data={returnData.replenishments.map(replenishment => {
                    return {
                        id: replenishment.id,
                        store: replenishment.store.name,
                        notes: replenishment.notes?.length > 52 ? <Tooltip title={replenishment.notes}><span>{replenishment.notes?.substring(0, 52)}...</span></Tooltip> : replenishment.notes,
                        // created_at: replenishment.created_at ? <FormattedDate date={replenishment.created_at} shortDate/> : '-',
                        expected_arrived_at: replenishment.expected_arrived_at ? <FormattedDate date={replenishment.expected_arrived_at} shortDate/> : '-',
                        status: (
                            // <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            //     {STATUS[replenishment.status]}
                            // </span>
                            <StatusPill status={replenishment.status} statuses={REPLENISHMENT_STATUSES}/>
                        ),
                        actions: (
                            <>
                            <Link to={SHOW_REPLENISHMENT.replace(":id", replenishment.id).replace(":warehouse_id",warehouse_id)} className="text-indigo-600 hover:text-indigo-900">
                                Recibir
                            </Link>
                            <br></br>
                            <Link onClick={()=>setReplenishmentInfo(replenishment)} >
                                Reimprimir etiqueta
                            </Link>
                            </>
                            
                        )
                    }
                })}/>

                <DialogView className="w-full" setOpen={setOpenDialogLabel} open={openDialogLabel} >
                <GenerateReplenishmentLabel
                  replenishment_info={replenishmentInfo}
                  onCancel = {() => {
                    setOpenDialogLabel(false)
                  }}
                  onSuccess = {() => {
                    setOpenDialogLabel(false)
                  }}
                />
              </DialogView>
                </>

                ):
                (<></>)
                }


        </PageView>
    )
}